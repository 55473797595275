import React, { useEffect, useState } from 'react';
import './Carousel3D.css';

const Carousel3D = () => {
    const [items, setItems] = useState([]);
    const [text, setText] = useState('');
    const [music, setMusic] = useState('');
    const [mControls, setMControls] = useState(false)
    const [mAutoPlay, setMAutoPlay] = useState(false)
    const [mLoop, setMLoop] = useState(false)
    const [openTime, setOpenTime] = useState(1000)
    const [loading, setLoading] = useState(true);
    const [radius, setRadius] = useState(150);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://krdeveloper.com/api/spin.php');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItems(data.items); // Assuming the API returns an array of items
                setText(data.text);
                if (data.music.url) {
                    setMusic(data.music.url);
                    setMControls(data.music.controls);
                    setMAutoPlay(data.music.autoplay);
                    setMLoop(data.music.loop);
                    setOpenTime(data.music.open);
                }
                setRadius(calculateRadius(data.items.length));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching the data: ', error);
                setLoading(false); // Stop loading in case of error
            }
        };

        fetchData();
    }, []);

    const calculateRadius = (itemCount) => {
        const baseRadius = 150;
        if (itemCount <= 5) {
            return baseRadius;
        }
        return baseRadius + (itemCount - 5) * 30;
    };

    useEffect(() => {
        if (!loading) {
            let autoRotate = true;
            let rotateSpeed = -60;
            let imgWidth = 150;
            let imgHeight = 150;

            setTimeout(init, openTime);

            const odrag = document.getElementById('drag-container');
            const ospin = document.getElementById('spin-container');

            ospin.style.width = imgWidth + "px";
            ospin.style.height = imgHeight + "px";

            const ground = document.getElementById('ground');
            ground.style.width = radius * 3 + "px";
            ground.style.height = radius * 3 + "px";

            function init(delayTime) {
                const aEle = ospin.children;

                for (let i = 0; i < aEle.length; i++) {
                    aEle[i].style.transform = `rotateY(${i * (360 / aEle.length)}deg) translateZ(${radius}px)`;
                    aEle[i].style.transition = "transform 1s";
                    aEle[i].style.transitionDelay = delayTime || (aEle.length - i) / 4 + "s";
                }
            }

            function applyTransform(obj) {
                if (tY > 180) tY = 180;
                if (tY < 0) tY = 0;

                obj.style.transform = `rotateX(${-tY}deg) rotateY(${tX}deg)`;
            }

            function playSpin(yes) {
                ospin.style.animationPlayState = yes ? 'running' : 'paused';
            }

            let sX, sY, nX, nY, desX = 0, desY = 0, tX = 0, tY = 10;

            if (autoRotate) {
                const animationName = rotateSpeed > 0 ? 'spin' : 'spinRevert';
                ospin.style.animation = `${animationName} ${Math.abs(rotateSpeed)}s infinite linear`;
            }

            if (music) {
                document.getElementById('music-container').innerHTML = `
                <video ${mControls ? 'controls' : ''} ${mAutoPlay ? 'autoplay' : ''}  ${mLoop ? 'loop' : ''} name="media"><source src="${music}" type="audio/mpeg"></video>
                `;
            }

            document.onpointerdown = (e) => {
                clearInterval(odrag.timer);
                e = e || window.event;
                sX = e.clientX;
                sY = e.clientY;

                document.onpointermove = function (e) {
                    e = e || window.event;
                    nX = e.clientX;
                    nY = e.clientY;
                    desX = nX - sX;
                    desY = nY - sY;
                    tX += desX * 0.1;
                    tY += desY * 0.1;
                    applyTransform(odrag);
                    sX = nX;
                    sY = nY;
                };

                document.onpointerup = function () {
                    odrag.timer = setInterval(() => {
                        desX *= 0.95;
                        desY *= 0.95;
                        tX += desX * 0.1;
                        tY += desY * 0.1;
                        applyTransform(odrag);
                        playSpin(false);
                        if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
                            clearInterval(odrag.timer);
                            playSpin(true);
                        }
                    }, 17);
                    document.onpointermove = document.onpointerup = null;
                };

                return false;
            };

            // document.onmousewheel = function (e) {
            //     e = e || window.event;
            //     const d = e.wheelDelta / 20 || -e.detail;
            //     radius += d;
            //     init(1);
            // };
        }
    }, [loading]);

    return (
        <div id="drag-container">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <div id="spin-container">
                        {items.map((item, index) => {
                            return item.type === 'video' ? (
                                <video key={index} controls autoPlay loop>
                                    <source src={item.src} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img key={index} src={item.src} alt={item.alt || `Image ${index + 1}`} />
                            );
                        })}
                    </div>
                    <p>{text}</p>
                    <div id="ground"></div>
                    <div id="music-container"></div>
                </>
            )}
        </div>
    );
};

export default Carousel3D;
